@import "./Typography.scss";
@import "./Grid.scss";

.ant-segmented {
  padding: 4px;

  .ant-segmented-item-label {
    @extend .label-strong;
    padding: 10px 24px;
  }
}

.ant-select-single {
  .ant-select-selector {
    color: #1d2023;
    & + .ant-select-arrow {
      color: #1d2023;
    }
  }
}

// .ant-menu-item-selected {
//   svg {
//     path:first-of-type {
//       fill: #292d32;
//     }
//     path:nth-of-type(2) {
//       stroke: transparent;
//     }
//   }
// }
