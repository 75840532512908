.ant-layout {
  .report {
    padding: 0;
  }
}
.report {
  background: #f9f4e9;
  padding: 100px;
  min-height: 100vh;
  .ant-card {
    border-radius: 16px;
  }
  .ant-divider-vertical {
    height: auto;
    margin: 40px 0;
  }
  .no-padding {
    .ant-card-body {
      padding: 0;
    }
  }
  .invited-text {
    display: block;
    color: #1d2023;
    font-size: 12px;
    font-weight: 500;
  }
  .influencer-card-statistics {
    .ant-statistic-title {
      color: #1d2023;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .ant-statistic-content-value {
      color: #1d2023;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 46px;
    }
  }
  .card-comment {
    .ant-card-body {
      padding: 40px 48px;
    }
  }

  .card-performance {
    color: #1d2023;
    .icon {
      height: 32px;
      width: 32px;
      path {
        stroke: #ffac39;
      }
    }
    .ant-typography {
      font-size: 28px;
      font-weight: 500;
    }
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .avatar-group {
    .ant-avatar-string {
      cursor: pointer;
    }
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(50px, 1fr)
    ); /* Ajuste minmax conforme necessário */
    gap: 4px; /* Espaçamento entre os itens */
  }

  .pending-header {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    border-radius: 6px;
    background: #ede8dd;
  }
  .icon-export {
    path {
      stroke-width: #cccccc;
    }
  }
  .pending-icon-value {
    color: #1d2023;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: #ffac39;
      }
    }
  }
  .pending-icon-label {
    margin-left: 24px;
    overflow: hidden;
    color: #1d2023;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .list-post {
    display: flex;
    padding-bottom: 12px;
    gap: 20px;
    width: 100%;
    overflow-x: auto; /* Permitindo overflow horizontal */
    overflow-y: hidden; /* Escondendo o overflow vertical */
    white-space: nowrap; /* Prevenindo a quebra de linha */

    .post {
      position: relative;
      min-width: 120px;
      cursor: pointer;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
      }

      .info {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        .text {
          display: flex;
          align-items: center;
          gap: 2px;
        }
      }
    }

    .social-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #000;
      color: #fff;
      top: 6px;
      right: 6px;
      padding: 4px;
      border-radius: 6px;
      svg {
        &.fill {
          path {
            fill: #fff;
          }
        }
        &.stroke {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .avatar-group {
      grid-template-columns: repeat(10, 24px);
      &.medium {
        grid-template-columns: repeat(8, 32px);
      }
      &.large {
        grid-template-columns: repeat(5, 48px);
      }
    }
  }
}
