.home-applications {
  .today {
    border: 2px solid #ffac39;
    border-radius: 16px;
  }

  .application {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    background-color: #fff;
    position: relative;

    padding: 24px 32px 24px 24px;
    border-radius: 16px;
    background: #fff;

    .button-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.pending {
      background-color: #ffac39;
      .box-icon {
        svg {
          path {
            stroke: #1d2023;
          }
        }
      }
    }

    .diamond {
      height: 20px;
      width: 20px;
      position: absolute;
      background-color: #ffac39;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      top: -5px;
      right: -4px;
      font-size: 12px;
    }

    .name {
      display: block;
      margin-bottom: 6px;
      overflow: hidden;
      color: var(--Gray-90, #1d2023);
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .box-icon {
      display: flex;
      gap: 8px;
      svg {
        path {
          stroke: #ffac39;
        }
      }
    }

    .ellipsis {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .value {
      color: #1d2023;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      overflow: hidden;
      font-family: Inter;
    }
  }
  .extra-info {
    display: flex;
    align-items: center;
    background-color: #ede8dd;
    border-radius: 0 0 16px 16px;
    margin-top: -12px;
    padding: 24px 30px 12px 30px;
    text-align: center;
    height: 50px;
  }

  .svg-16 {
    height: 16px;
    width: 16px;
  }

  .ant-avatar-group {
    display: flex;
    flex-direction: row-reverse;
    .ant-avatar {
      margin-right: -15px;
      border-color: #ffac39;
    }
  }
}
