.business-message {
  display: flex;
  word-break: break-word;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  margin-left: 20%;

  &__content {
    background-color: #ffac39;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px 20px;
  }

  &__sender {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    text-align: left;
  }
}
