.field-filter {
  display: flex;
  &.content {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    .ant-input-number {
      width: 100%;
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
  .container {
    border-radius: 6px;
    background: #fff;
    padding: 6px 12px;
    display: flex;
    gap: 4px;
    align-items: center;
    &.open {
      background: rgba(0, 0, 0, 0.05);
    }
    &.has-value {
      border-radius: 6px 0 0 6px;
    }
  }
  .button-clear {
    border: none;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0 6px 6px 0;
  }
}
