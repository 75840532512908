.influencer-modal {
  .list-social {
    display: flex;
    gap: 8px;
    .box-social {
      flex: 1;
      display: flex;
      flex-direction: column;
      border: 1px solid #e6e6e6;
      padding: 18px;
      border-radius: 8px;
      gap: 2px;
      .title {
        font-size: 12px;
      }
      .icon-metric {
        height: 14px;
        width: 14px;
      }

      .metrics {
        display: flex;
        gap: 8px;
        .social-metric {
          display: flex;
          gap: 2px;
        }
      }

      &.link {
        cursor: pointer;
        position: relative;
        .icon-url {
          position: absolute;
          right: 10px;
          top: 10px;
          opacity: 0.5;
        }
      }
      .tag-metric {
        font-size: 10px;
        border-radius: 20px;
        background: #f1f1f1;
        padding: 2px 6px;
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .list-post {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow-x: auto; /* Permitindo overflow horizontal */
    overflow-y: hidden; /* Escondendo o overflow vertical */
    white-space: nowrap; /* Prevenindo a quebra de linha */
    .post {
      position: relative;
      min-width: 120px;
      flex: 1;
      cursor: pointer;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
        position: relative;
      }
      .info {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        .text {
          display: flex;
          align-items: center;
          gap: 2px;
        }
      }
    }
    .social-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #000;
      color: #fff;
      margin-left: 88px;
      top: 6px;
      right: 6px;
      padding: 4px;
      border-radius: 6px;
    }
  }
  .list-categories {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 100%;
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .image-post {
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
    max-width: 50px;
  }
  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #ffdeb0;
    background-color: #fff;
    height: 60px;
    width: 60px;
    position: relative;
    z-index: 1;

    .month {
      padding: 2px 1px;
      background: #ffdeb0;
      width: 100%;
      border-radius: 16px 16px 0 0;
      text-align: center;
      color: #1d2023;
      text-overflow: ellipsis;
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 175% */
      text-transform: uppercase;
    }
    .day {
      color: #1d2023;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
